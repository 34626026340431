
.contact__options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1.2rem;
}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
    min-width: 25%;

}

.contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}


.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}


/* medium devices */
@media screen and (max-width: 1024px) {
    .contact__option {
        min-width: 33%;
        gap: 20%;
    }
    .contact__options {
        gap: 10%;
    }

}

/* small devices */
@media screen and (max-width: 600px) {
    .contact__options {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
    }

}