header {
    /* height: 100%; */
    /* display: flex; */
    padding-top: 5rem;
    /* overflow:scroll; */
    object-fit: contain;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;

}

/* CTA */

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* socials */

.header__socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.2rem;
    justify-content: center;
    top: 2rem;
    position: relative;
}

.header__socials::before, .header__socials::after {
    content: '';
    width: 2rem;
    height: 1px;
    background: var(--color-primary);
}

/* me */
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 25rem;
    position: inherit;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 4rem 1.5rem 1.5rem 1.5rem;
}

/* medium devices */
/* @media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }
}

/* small devices */
/* @media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    
} */ 
